<template>
  <div>
    <!-- Modal -->
    <div class="modal fade" id="modalDuplicar">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Duplicar plantilla</h5>
          </div>
          <div class="modal-body text-center" v-html="message"></div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              @click="open"
              :disabled="estado"
            >Cancelar</button>
            <button type="button" class="btn btn-success" @click="duplicar" v-if="!estado">Duplicar</button>
            <button class="btn btn-success" type="button" disabled v-else>
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              Duplicando...
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    message: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      estado: false,
    };
  },
  methods: {
    open() {
      this.estado = false;
      $("#modalDuplicar").modal("toggle");
    },
    duplicar() {
      this.estado = true;
      this.$emit("duplicar");
    },
  },
};
</script>